import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const EDITORIAL_BASE_URL = API_BASE_URL + "/editorials";

export const getEditorials = () => {
    return fetchFrom(EDITORIAL_BASE_URL);
}

export const getEditorialById = id => {
    return fetchFrom(EDITORIAL_BASE_URL + "/" + id);
}

function fetchFrom(url) {
    return axios.get(url)
        .then(res => {
            return res.data;
        });
}
