import React from "react";
import {BrowserRouter, Route} from "react-router-dom";
import style from "./App.module.css"
import Header from "./components/Header/Header";
import LandingPage from "./components/LandingPage/LandingPage";
import About from "./components/About/About";
import Footer from "./components/Footer/Footer";
import EditorialsList from "./components/EditorialsList/EditorialsList";
import EditorialView from "./components/EditorialView/EditorialView";

function App() {
    return (
        <BrowserRouter>
            <div className={style.componentRoot}>
                <div className={style.header}>
                    <Header/>
                </div>
                <div className={style.content}>
                    <Route exact path="/" component={LandingPage}/>
                    <Route path="/about" component={About}/>
                    <Route path="/editorials" component={EditorialsList}/>
                    <Route path="/editorial/:id" component={EditorialView}/>
                </div>
                <div className={style.footer}>
                    <Footer/>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
