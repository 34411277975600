import React from "react";
import style from "./Footer.module.css";

class Footer extends React.Component {
    render() {
        return (
            <footer className={style.componentRoot}>
                <a href="https://www.instagram.com/never.magazine/" rel="noreferrer" target="_blank">instagram</a>
                <a href="https://www.youtube.com/channel/UCe0Vf1oYhQdyhuPTk4dnMaA" rel="noreferrer" target="_blank">youtube</a>
                <a href="https://www.pinterest.ru/nevermag" rel="noreferrer" target="_blank">pinterest</a>
            </footer>
        );
    }
}

export default Footer;
