import React from "react";
import s from "./Header.module.css";
import Menu from "./Menu/Menu";
import Logo from "../Logo/Logo";
import {NavLink} from "react-router-dom";

class Header extends React.Component {
    render() {
        return (
            <header className={s.componentRoot}>
                <Menu/>
                <div className={s.logoWrapper}>
                    <NavLink to="/">
                        <Logo/>
                    </NavLink>
                </div>
            </header>
        );
    }
}

export default Header;
