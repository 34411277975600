import React from "react";
import s from "./EditorialsList.module.css"
import {getEditorials} from "../../api/api";
import EditorialCard from "./EditorialCard/EditorialCard";
import {NavLink} from "react-router-dom";

class EditorialsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editorials: this.props.editorials || [],
        }
    }

    componentDidMount() {
        getEditorials()
            .then(data => {
                this.setState({editorials: data});
            })
            .catch(err => {
                this.setState({error: err.toString()})
            });
    }

    render() {
        let error = this.state.error;
        let content;
        if (this.state.editorials.length > 0) {
            content = this.state.editorials.slice(0).reverse().map(
                editorial =>
                    <NavLink to={"/editorial/" + editorial.id} key={editorial.id}>
                        <div className={s.cardContainer}>
                            <div className={s.cardWrapper}>
                                <EditorialCard editorial={editorial}/>
                            </div>
                        </div>
                    </NavLink>
            )
        } else {
            content = "No editorials found"
        }
        return (
            <div className={s.componentRoot}>
                {error}
                {content}
            </div>
        );
    }
}

export default EditorialsList;
