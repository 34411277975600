import React from "react";
import s from "./EditorialCard.module.css";

class EditorialCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.editorial.id,
            photoIds: props.editorial.photoIds,
            title: props.editorial.title
        }
    }

    render() {
        return (
            <div className={s.componentRoot}>
                <img className={s.mainImage}
                     src={process.env.REACT_APP_API_BASE_URL + "/photos/preview/" + this.state.photoIds[0]}
                     alt=""/>
                <p className={s.title}>{this.state.title}</p>
            </div>
        );
    }
}

export default EditorialCard;
