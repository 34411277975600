import React from "react";
import {NavLink} from "react-router-dom";
import s from "./Menu.module.css";

class Menu extends React.Component {

    render() {
        return (
            <div className={s.componentRoot}>
                <NavLink to="/editorials">Editorials</NavLink>
                <a href="https://www.instagram.com/never.magazine" rel="noreferrer" target="_blank">Instagram</a>
                <a href="https://kavyar.com/never-magazine" rel="noreferrer" target="_blank">Submit</a>
                <a href="https://www.magcloud.com/browse/issue/2688877" rel="noreferrer" target="_blank">Shop</a>
                <NavLink to="/about">About</NavLink>
            </div>
        );
    }
}

export default Menu;
