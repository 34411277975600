import React from "react";
import style from "./LandingPage.module.css";
import Logo from "../Logo/Logo"

class LandingPage extends React.Component {
    render() {
        return (
            <div className={style.componentRoot}>
                <div className={style.logoWrapper}>
                    <Logo/>
                </div>
            </div>
        );
    }
}

export default LandingPage;
