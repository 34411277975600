import React from "react";
import {getEditorialById} from "../../api/api.js";
import s from "./EditorialView.module.css"

class EditorialView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "",
            description: "",
            photos: []
        };
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            const id = this.props.match.params.id;
            getEditorialById(id)
                .then(data => {
                    this.setState({
                        title: data.title,
                        description: data.description,
                        photos: data.photoIds.map(photoId => ({photoId}))
                    })
                });
        }
    }

    render() {
        return (
            <div>
                <div className={s.title}>{this.state.title}</div>
                <div className={s.description}>{this.state.description}</div>
                <div className={s.photoList}>
                    {this.state.photos.map(photo =>
                        <div className={s.photoWrapper}>
                            <img className={s.photo}
                                 src={process.env.REACT_APP_API_BASE_URL + "/photos/content/" + photo.photoId}
                                 alt=""/>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default EditorialView;
