import React from "react";
import style from "./Logo.module.css";
import logo from "./NeverMagazine.png";

class Logo extends React.Component {

    render() {
        return (
            <div className={style.componentRoot}>
                <img src={logo} alt="The Never Magazine logo"/>
            </div>
        );
    }
}

export default Logo;
