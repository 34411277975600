import React from "react";
import descriptionImage from "./about.jpg";
import s from "./About.module.css";

class About extends React.Component {
    render() {
        return (
            <div className={s.componentRoot}>
                <h2 className={s.title}>About</h2>
                <div className={s.description}>
                    <img src={descriptionImage} className={s.description_image} alt=""/>
                    <p className={s.description_text}>
                        Founded in 2020, NEVER magazine is an inspiring Fashion magazine
                        for both men and women at any country and any age.
                        We admire and love fresh look at things, so be confident to submit your work,
                        your art and show it to others and be proud of yourself.
                        <br/>
                        We are happy to feature editorial contributions made by
                        independent photographers and industry experts,
                        and we are looking forward to meet You on our pages!
                        <br/><br/>
                        <span className={s.contact}>
                            <em>
                            For Business enquires, please contact us via email:
                            <a href="mailto:nevermagazine@yahoo.com"> nevermagazine@yahoo.com</a>
                            </em>
                        </span>
                    </p>
                </div>
            </div>
        );
    }
}

export default About;
